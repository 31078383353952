<!-- @format -->

<template>
  <div class="flex justify-center flex-col lg:flex-row">
    <div class="mt-1 ml-8">
      <a
        class="mr-1 lg:fluid-type-nfpsocial"
        :href="$config.facebook.link"
        target="_blank"
      >
        <font-awesome-icon class="mb-1" :icon="['fab', 'facebook-square']" />
      </a>
      <a
        class="mr-1 lg:fluid-type-nfpsocial"
        :href="$config.youtube.link"
        target="_blank"
      >
        <font-awesome-icon class="mb-1" :icon="['fab', 'youtube-square']" />
      </a>
      <a
        class="mr-1 lg:fluid-type-nfpsocial"
        href="https://www.instagram.com/alamodefilm/"
        target="_blank"
      >
        <font-awesome-icon class="mb-1" :icon="['fab', 'instagram']" />
      </a>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
